import { $ajaxService } from './request'

// 长度超过一定限度就用 '...'
function overflow(text, len) {
  if (typeof text !== 'string') return text
  return text.length > len ? text.substr(0, len) + '...' : text
}

// 首页的最近上新数据接口
export const getHotMaterial = async () => {
  const res = await $ajaxService.post(
    $ajaxService.baseUrl + 'api_material_library/home'
  )

  return Array.from(res.data, (item) => ({
    id: item.id,
    imageURL: item.material_url,
    parent: item.category_name,
    type: item.material_type,
    title: overflow(item.title, 8),
    content: overflow(item.content, 20),
    share: item.is_share,
    updateTime: item.update_time
  }))
}

// 素材库列表
export const getMaterialList = async ({ page, size }) => {
  const res = await $ajaxService.post(
    $ajaxService.baseUrl + 'api_material_library/list',
    { page, page_size: size, type: 'image_text' }
  )

  return Array.from(res.data.list, (item) => ({
    id: item.id,
    type: item.type,
    title: item.title,
    introduction: item.introduction,
    imageURL: item.url,
    updateTime: item.update_time
  }))
}

// 素材库详情
export const getMaterialDetail = async (id) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'api_content/detail',
    { id }
  )

  return {
    id: data.id,
    title: data.title,
    introduction: data.introduction,
    content: data.content,
    images: Array.from(data.file || [], (item) => item.material_url)
  }
}

// 生成群发标签
export const buildGroupLabel = async (name) => {
  const res = await $ajaxService.post(
    $ajaxService.baseUrl + 'crop_tags/add_temporary_tag',
    { tag_name: name }
  )

  return {
    id: res.data.tag_id,
    name: res.data.tag_name,
    time: res.data.expire_time
  }
}

// 群发标签与用户进行绑定
export const bindGroupLabel = async (tagId, userids) => {
  const res = await $ajaxService.post(
    $ajaxService.baseUrl + 'crop_tags/customer_temporary_tag',
    { tag_id: tagId, external_code: userids }
  )

  return res
}
