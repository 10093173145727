import axios from 'axios'
import { Notify, Toast, Dialog } from 'vant'
import { store } from '@/store'
import { ignoreEmptyParams } from '@/utils'
export const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 100000,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/formdata;charset=UTF-8'
  }
})

// 请求拦截器
service.interceptors.request.use((config) => {
  // 携带token
  if (store.getters.token) {
    config.headers.token = store.getters.token
  }
  // console.log('环境', process.env.NODE_ENV)
  // 开发环境 配置token

  // 开发环境
  if (process.env.NODE_ENV === 'development') {
    // console.log('插入了token')
    config.headers.token = ''
  }

  return config
})

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 重新鉴权
    if (response.data.code == 401) {
      confimAuthLogin()
    }
    if (response.data.code == 402) {
      location.href = '/no_permission'
      return Promise.reject(response.data)
    }
    return Promise.resolve(response.data)
  },
  (error) => {
    // removePending(error.config)
    return Promise.reject(error)
  }
)
const baseUrl = process.env.VUE_APP_BASE_API
// console.log('请求前缀---------', baseUrl)
class AjaxService {
  constructor() {
    this.name = 'AjaxService'
    this.baseUrl = baseUrl
    this.xhrQueue = {} // ajax请求队列
  }
  /**
   * 统一ajax请求：
   * 1 入参统一，可以对入参统一预处理
   * 2 根据业务code 把失败业务归为reject
   * 3 请求自动弹出loading
   * 4 自动缓存同时段的相同请求，允许手动取消请求
   * 5 提供超时/失效后的重复请求的能力
   * 6
   * @param {Object} options 新增以下属性
   *   loading | Boolean: 默认false，设置为true则请求前自动弹出loading
   *   retry | Number： 默认0次，可设置对应重新请求次数
   *   ignoreErrorcode | Boolean： 默认false，设置true则取消业务状态码是否为0判断
   *   ignoreErrorMsg | Boolean： 默认false， 设置为true则取消失败业务的自动错误提示
   *   ignoreEmptyParams | Boolean： 默认false， 设置为true则自动去除空参数（空字符串空对象空数组nullundefiend）
   */
  request({ method, url, params = null, options = {} }) {
    if (params && options.ignoreEmptyParams) {
      params = ignoreEmptyParams(params)
    }
    switch (method.toUpperCase()) {
      case 'GET':
        options.params = params
        break
      case 'POST':
        options.data = params
        break
    }
    let loading
    if (options.loading) {
      loading = Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
    }
    const CancelToken = axios.CancelToken
    let sourceCancel
    const config = Object.assign(
      {
        url,
        method,
        cancelToken: new CancelToken(function executor(cancel) {
          // executor 函数接收一个 cancel 函数作为参数
          sourceCancel = cancel
        })
      },
      options
    )
    /* console.log(config) */
    const _id = JSON.stringify({
      url,
      params,
      method
    })
    // 相同请求直接返回缓存
    if (_id in this.xhrQueue) return this.xhrQueue[_id]
    const requestPromise = new Promise((resolve, reject) => {
      // 成功回调
      const successCb = (resData) => {
        if (options.ignoreErrorcode) {
          resolve(resData)
          return
        }
        let resultCode = resData.code
        let errorMsg = resData.msg || ''
        switch (resultCode) {
          case 0:
            reject(resData)
          // Notify({
          //   type: 'danger',
          //   message: errorMsg
          // })
          case 200:
            resolve(resData)
            break
          case 42025:
            resolve(resData)
            break
          case 40001:
            resolve(resData)
            break
          case 42029:
            resolve(resData)
            break
          case 40404:
            resolve(resData)
            break
          case 40405:
            resolve(resData)
            break
          case 40406:
            resolve(resData)
            break
          case 401:
            // confimAuthLogin()
            reject(resData)
            break
          case 402:
            location.href = '/no_permission'
            reject(resData)
          default:
            // 限制错误提示长度，避免过长占满整个屏幕
            !options.ignoreErrorMsg &&
              Notify({
                type: 'danger',
                message: errorMsg && errorMsg.substr(0, 100)
              })
            reject(resData)
            break
        }
      }
      // 失败回调
      const errorCb = (error) => {
        if (config.retry) {
          config.retry -= 1
          service.request(config).then(successCb).catch(errorCb)
        } else {
          reject(error)
        }
      }
      service.request(config).then(successCb).catch(errorCb)
    })
    // 给promise对象添加取消axios请求的方法
    requestPromise.cancel = sourceCancel
    this.queue(_id, requestPromise) // 存进队列
    return requestPromise.finally(() => {
      requestPromise.cancel = sourceCancel = null
      this.queueCleaner(_id) // 释放队列中的subscription
      if (options.loading) {
        loading.clear()
      }
    })
  }

  ajax(method, url, params, options) {
    return this.request({
      method,
      url,
      params,
      options
    })
  }

  get(url, params, options) {
    return this.request({
      method: 'GET',
      url,
      params,
      options
    })
  }

  post(url, params, options) {
    return this.request({
      method: 'POST',
      url,
      params,
      options
    })
  }

  setHeaderAuthorization(token) {
    service.defaults.headers.common['token'] = `${token}`
  }
  queue(_id, _promise) {
    // 负责存队列
    // key: 请求的url param method组成的string ; value: 请求promise
    this.xhrQueue[_id] = _promise
    return _id
  }
  queueCleaner(id) {
    // 删除队列中某个source
    delete this.xhrQueue[id]
  }
  xhrCancel() {
    // 清除所有队列
    Object.keys(this.xhrQueue).forEach((id) => {
      let source = this.xhrQueue[id]
      source.cancel() // 退订
    })
  }
}
export const $ajaxService = new AjaxService()

/**
 * 微信授权重定向地址
 * @param  {} origin_url 跳转前的url
 */
const getWechatRedirectUrl = (origin_url) => {
  store.commit('user/SET_TOKEN', '')

  // return $ajaxService.get('/api/guide/get_redirect_url', {
  //   params: {
  //     origin_url
  //   }
  // })

  return service.get($ajaxService.baseUrl + 'guide/get_redirect_url', {
    params: {
      origin_url
    }
  })
  // return $ajaxService.get($ajaxService.baseUrl + 'guide/get_redirect_url', {
  //   origin_url
  // })
}

/**
 * 删除url中的指定参数
 * @param url
 * @param key
 * @return {string|*}
 */
export function delUrlParam(url, key) {
  let baseUrl = url.split('?')[0] + '?'
  let query = url.split('?')[1]
  if (query.indexOf(key) > -1) {
    let obj = {}
    let arr = query.split('&')
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=')
      obj[arr[i][0]] = arr[i][1]
    }
    delete obj[key]
    let url =
      baseUrl +
      JSON.stringify(obj)
        .replace(/[\"\{\}]/g, '')
        .replace(/\:/g, '=')
        .replace(/\,/g, '&')
    return url
  } else {
    return url
  }
}

export const authLogin = async () => {
  const { data } = await getWechatRedirectUrl(
    // delUrlParam(location.href, 'code')
    location.href
  )
  // let url = 'https/://open.weixin.qq.com/connect/oauth2/authorize?appid=wwbebe56ddead91b0a&redirect_uri=https%3A%2F%2Fwe-customer-guide-k8s.dev.freshgood.cn%2Fworkbench%3Fcode%3DqAJGfRG30tSWMTLGNdhtjaxSdvI08UIOyJZmjyTthLo%26state%3D0%26code%3DGw3dWZ6pM_Lbi5xUHeH3ih63mw1zHAYD6DCCkePWt3c%26state%3D0%26code%3DbozmB0PsT_WewNOMbkmsmi09R_prLdB9Ik4OyVmt6pc%26state%3D0%26code%3Djvc6VyNHRV9un2H4hinSkH4nuuQ-rBOW8VmoBJFR-z4%26state%3D0%26code%3DKUl8QAXQo7mWQqf3Z4tyTkY5POQcrZeRkV9gl1a-qQw%26state%3D0%26code%3D9XizbDCJLuk806G1Lv-BDRFabbAFduas0LXNbXig8tA%26state%3D0&response_type=code&scope=snsapi_base&state=0#wechat_redirect'
  // let arr = data.redirect_url.split('&')
  // let url = ''
  // if (arr.length > 1) {
  //   url = arr[0].replace(/[code\[\]][\s\S]+/, '') + arr[arr.length - 1]
  // } else {
  //   url = data.redirect_url
  // }
  location.href = data.redirect_url
}

function confimAuthLogin() {
  Dialog.confirm({
    title: '登录失效',
    message: '登录已失效，是否重新登录？'
  }).then(() => {
    authLogin()
  })
}
// // 重复请求处理：取消上一次请求
// // 存储请求列表
// const pendingRequest = new Map()
// // 请求结束
// function removePending(config) {
//   const { method, params, baseURL, data } = config
//   const url = [method, params, baseURL, data].join('&')
//   if (pendingRequest.has(url)) {
//     pendingRequest.delete(url)
//   }
// }
// // 新增请求
// function addPending(config) {
//   const { method, params, baseURL, data } = config
//   let query = []
//   let payload = []
//   if (params) {
//     query = Object.entries(params).map((item) => `${item[0]}=${item[1]}`)
//   }
//   if (data) {
//     payload = Object.entries(params).map((item) => `${item[0]}=${item[1]}`)
//   }
//   const url = [method, baseURL].concat(query).concat(payload).join('&')
//   if (!pendingRequest.has(url)) {
//     config.cancelToken = new CancelToken((c) => {
//       pendingRequest.set(url, c)
//     })
//   } else {
//     const cancel = pendingRequest.get(url)
//     cancel()
//   }
// }
