// vue-router@next  https://next.router.vuejs.org/guide/

import { createRouter, createWebHistory } from 'vue-router'

import { store } from '../store'

import { getWechatToken } from '@/service/wechat.service'
import { authLogin } from '@/service/request'

// 1. Import pages
// const Workbench = () => import('../views/workbench/index.vue')
import Workbench from '../views/workbench/index.vue'

const Community = () => import('../views/community/index.vue')
// const CommunityAnalyse = () => import('../views/community/analyse.vue')
// const CommunityDetail = () => import('../views/community/detail.vue')
const CommunityDetail = () => import('../views/customer/group/portrait.vue')
const CommunityAnalDyna = () => import('../views/community/analyse-dynamic.vue')

const Tool = () => import('../views/tool/index.vue')
const Coupon = () => import('../views/tool/coupon/index.vue')
const Material = () => import('../views/tool/material/index.vue')
const MaterialGuide = () => import('../views/tool/material/guide.vue')
const MaterialWeapp = () => import('../views/tool/material/weapp.vue')
const Welcome = () => import('../views/tool/welcome/index.vue')
const MarketingSuggestions = () => import('../views/tool/marketing/index.vue')
const Speechcraft = () => import('../views/tool/speechcraft/index.vue')
const SpeechcraftSearch = () => import('../views/tool/speechcraft/search.vue')

const Customer = () => import('../views/customer/index.vue')
const CustomerSearch = () => import('../views/customer/search/index.vue')
const CustomerPortrait = () => import('../views/customer/portrait/index.vue')
const CustomerTag = () => import('../views/customer/tag/index.vue')
const CustomerFollow = () => import('../views/customer/follow/index.vue')

const GuideMarketing = () => import('../views/guide/marketing.vue')
const GuideFigure = () => import('../views/guide/figure.vue')

const share = () => import('../views/share/index.vue')
const beforeShare = () => import('../views/share/beforeShare.vue')

const Login = () => import('../views/auth/login/index.vue')
const NotFound = () => import('../views/auth/not-found/index.vue')
const Loading = () => import('../views/loading/index.vue')

const TaskList = () => import('../views/task/index.vue')
const TaskEdit = () => import('../views/task/taskEdit.vue')
const MaketingTaskDetail = () =>
  import('../views/tool/marketing/detail/task_detail.vue')
const KnowledgeDetail = () =>
  import('../views/tool/marketing/detail/knowledge_detail.vue')
const KnowledgeSearch = () =>
  import('../views/tool/marketing/search/knowledge_search.vue')
const RecommandDetail = () =>
  import('../views/tool/marketing/detail/recommand_detail.vue')
const LabelMarketingDetail = () =>
  import('../views/tool/marketing/detail/labelMarketing_detail.vue')
const OpenChart = () => import('../views/guide/openCharts.vue')
const guidePage = () => import('../views/guide/guidePage.vue')
// const Layout = () => import('../components/layout/index.vue')
const PreviewPage = () => import('../views/preview/preview.vue')
const personalSpeechEdit = () =>
  import('../views/tool/marketing/edit/speechEdit.vue')
const PersonalSpeechSearch = () =>
  import('../views/tool/marketing/search/speeach_search.vue')
const NoJuisdiction = () => import('../views/noJurisdiction/index.vue')
const OpenMiniProgram = () => import('../views/tool/openMinProgram/index.vue')

const b2cMall = () => import('../views/b2cMall/index.vue')
const b2cCouponSearch = () => import('../views/b2cMall/coupon/search.vue')
const b2cGoodsSearch = () => import('../views/b2cMall/goods/search.vue')
const b2cCheckQr = () => import('../views/b2cCheck_qr/index.vue')
const CheckQr = () => import('../views/check_qr/index.vue')
const mallShare = () => import('../views/mall_share/index.vue')
const goodsSearch = () => import('../views/mall_share/goodsSearch.vue')
import Layout from '../components/layout/index.vue'
import { nextTick } from 'vue'

// 2. Define routes
const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/workbench',
    children: [
      {
        path: 'workbench',
        name: 'workbench',
        component: Workbench,
        meta: {
          title: '首页',
          hiddenNavigation: true
        }
      },
      //客户--------------------start
      {
        path: 'customer',
        name: 'customer',
        component: Customer,
        meta: {
          title: '客户',
          hiddenNavigation: true
        }
      },
      {
        path: 'customer-search',
        name: 'customer-search',
        component: CustomerSearch,
        meta: {
          title: '客户搜索'
        }
      },
      {
        path: 'customer-portrait',
        name: 'customer-portrait',
        component: CustomerPortrait,
        meta: {
          title: '客户画像',
          hiddenNavigation: true
        }
      },
      {
        path: 'customer-tag',
        name: 'customer-tag',
        component: CustomerTag,

        meta: {
          title: '企业标签编辑',
          hiddenNavigation: true
        }
      },
      {
        path: 'customer-follow/:id/:type',
        name: 'customer-follow',
        component: CustomerFollow,
        meta: {
          title: '客户跟进',
          hiddenNavigation: true
        }
      },

      // 任务-------------------------start
      {
        path: 'task',
        name: 'task',
        component: TaskList,
        meta: {
          title: '任务',
          hiddenNavigation: true
        }
      },
      {
        path: 'taskEdit',
        name: 'taskEdit',
        component: TaskEdit,
        meta: {
          title: '编辑任务',
          hiddenNavigation: true
        }
      },

      // 任务-------------------------end
      //客户------------------------end
      {
        path: 'community',
        name: 'community',
        component: Community,
        meta: {
          title: '社群',
          hiddenNavigation: true
        }
      },
      //   {
      //     path: 'community/analyse',
      //     name: 'community-analyse',
      //     component: CommunityAnalyse,
      //     meta: {
      //       title: '群看板',
      //       hiddenNavigation: true
      //     }
      //   },
      {
        path: 'community/analyse/dynamic',
        name: 'community-analyse-dynamic',
        component: CommunityAnalDyna,
        meta: {
          title: '群用户动态',
          hiddenNavigation: true
        }
      },
      //   {
      //     path: 'community/:id',
      //     name: 'community-detail',
      //     component: CommunityDetail,
      //     props: true,
      //     meta: {
      //       title: '我的客户群'
      //     }
      //   },
      {
        path: 'community/detail',
        name: 'community-detail-new',
        component: CommunityDetail,
        props: true,
        meta: {
          title: '群画像',
          hiddenNavigation: true
        }
      },
      //工具------------------------start
      {
        path: 'tool',
        name: 'tool',
        component: Tool,
        meta: {
          title: '工具',
          hiddenNavigation: true
        }
      },
      // 用于打开小程序
      {
        path: 'openMiniProgram',
        name: 'openMiniProgram',
        component: OpenMiniProgram,
        meta: {
          title: '',
          hiddenNavigation: true
        }
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: Coupon,
        meta: {
          title: '优惠券'
        }
      },
      {
        path: 'material',
        name: 'material',
        component: Material,
        meta: {
          title: '素材库'
        }
      },
      {
        path: 'material/:id',
        name: 'material-guide',
        component: MaterialGuide,
        props: true,
        meta: {
          title: '引导页'
        }
      },
      {
        path: 'material/weapp',
        name: 'material-weapp',
        component: MaterialWeapp,
        meta: {
          title: '小程序',
          hiddenNavigation: true
        }
      },
      {
        path: '/welcome',
        name: 'welcome',
        component: Welcome,
        meta: {
          title: '欢迎语设置'
        }
      },
      //快捷发送
      // {
      //   path: 'marketing-suggestions',
      //   name: 'marketing-suggestions',
      //   component: MarketingSuggestions,
      //   meta: {
      //     title: '快捷发送',
      //     // title: '营销建议',
      //     hiddenNavigation: true
      //   }
      // },
      {
        path: 'marketing-suggestions',
        name: 'marketing-suggestions',
        component: MarketingSuggestions,
        meta: {
          title: '任务',
          // title: '营销建议',
          hiddenNavigation: true
        }
      },
      {
        path: 'marketing-ai_replay',
        name: 'ai_replay',
        component: MarketingSuggestions,
        meta: {
          title: '推荐回复',
          hiddenNavigation: true
        }
      },
      {
        path: 'marketing-label',
        name: 'marketingLabel',
        component: MarketingSuggestions,
        meta: {
          title: '标签营销',
          hiddenNavigation: true
        }
      },
      {
        path: 'marketing-knowledge',
        name: 'knowledge',
        component: MarketingSuggestions,
        meta: {
          title: '知识库',
          hiddenNavigation: true
        }
      },
      {
        path: 'marketing-personal_speech',
        name: 'personal_speech',
        component: MarketingSuggestions,
        meta: {
          title: '我的话术',
          hiddenNavigation: true
        }
      },

      {
        path: '/marketing-task-detail',
        name: 'marketing-task-detail',
        component: MaketingTaskDetail,
        meta: {
          title: '详情',
          hiddenNavigation: true
        }
      },
      {
        path: '/knowledge-detail',
        name: 'knowledge-detail',
        component: KnowledgeDetail,
        meta: {
          title: '详情',
          hiddenNavigation: true
        }
      },
      {
        path: '/labelmarketing-detail',
        name: 'labelmarketing-detail',
        component: LabelMarketingDetail,
        meta: {
          title: '详情',
          hiddenNavigation: true
        }
      },
      {
        path: '/knowledge-search',
        name: 'knowledge-search',
        component: KnowledgeSearch,
        meta: {
          title: '搜索',
          hiddenNavigation: true
        }
      },
      {
        path: '/personalspeech-search',
        name: '/personalspeech-search',
        component: PersonalSpeechSearch,
        meta: {
          title: '搜索',
          hiddenNavigation: true
        }
      },
      {
        path: '/recommand-detail',
        name: 'recommand-detail',
        component: RecommandDetail,
        meta: {
          title: '详情',
          hiddenNavigation: true
        }
      },
      {
        path: '/personalspeech-edit',
        name: 'personalspeech-edit',
        component: personalSpeechEdit,
        meta: {
          title: '编辑',
          hiddenNavigation: true
        }
      },
      //个人会话工具栏
      {
        path: 'customer-portrait-tool',
        name: 'customer-portrait-tool',
        component: CustomerPortrait,
        meta: {
          title: '客户画像',
          hiddenNavigation: true
        }
      },
      {
        path: 'speechcraft',
        name: 'speechcraft',
        component: Speechcraft,
        meta: {
          title: '话术库',
          hiddenNavigation: true
        }
      },
      {
        path: 'speechcraft',
        name: 'speechcraft-search',
        component: SpeechcraftSearch,
        meta: {
          title: '话术查询',
          hiddenNavigation: true
        }
      },
      {
        path: 'preview-page',
        name: 'preview-page',
        component: PreviewPage,
        meta: {
          title: '文件预览',
          hiddenNavigation: true
        }
      },
      //工具------------------------end
      // 核销页---------------------start
      {
        path: '/check-qr',
        name: 'check-qr',
        component: CheckQr,
        meta: {
          title: '核销凭证',
          hiddenNavigation: true
        }
      },
      // 核销页---------------------end
      // B2C核销页---------------------start
      {
        path: '/b2cCheck-qr',
        name: 'b2cCheck-qr',
        component: b2cCheckQr,
        meta: {
          title: '核销凭证',
          hiddenNavigation: true
        }
      },
      // B2C核销页---------------------end
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          title: '登录'
          // hiddenNavigation: true
        }
      }
    ]
  },
  // 聊天工具栏入口页面
  {
    path: '/b2cMall/index',
    name: 'b2cMall',
    component: b2cMall,
    meta: {
      title: 'b2cMall',
      hiddenNavigation: true
    }
  },
  {
    path: '/b2cCoupon-search',
    name: 'b2cCoupon-search',
    component: b2cCouponSearch,
    meta: {
      title: '搜索',
      hiddenNavigation: true
    }
  },
  {
    path: '/b2cGoods-search',
    name: 'b2cGoods-search',
    component: b2cGoodsSearch,
    meta: {
      title: '搜索',
      hiddenNavigation: true
    }
  },
  {
    path: '/guide/figure',
    name: 'guide-figure',
    component: GuideFigure,
    meta: {
      title: '客户画像',
      hiddenNavigation: true
    }
  },
  {
    path: '/guide/marketing',
    name: 'guide-marketing',
    component: GuideMarketing,
    meta: {
      title: '营销建议',
      hiddenNavigation: true
    }
  },
  {
    path: '/openChart',
    name: 'openChart',
    component: OpenChart,
    meta: {
      title: '打开会话',
      hiddenNavigation: true
    }
  },
  {
    path: '/guidePage',
    name: 'guidePage',
    component: guidePage,
    meta: {
      title: '引导页',
      hiddenNavigation: true
    }
  },
  // 导购分享
  {
    path: '/beforeShare',
    name: 'beforeShare',
    component: beforeShare,
    meta: {
      title: '导购分享',
      hiddenNavigation: true
    }
  },
  {
    path: '/share',
    name: 'share',
    component: share,
    meta: {
      title: '导购分享',
      hiddenNavigation: true
    }
  },
  {
    path: '/mallShare',
    name: 'mallShare',
    component: mallShare,
    meta: {
      title: '奥斯芬分享',
      hiddenNavigation: true
    }
  },
  {
    path: '/goodsSearch',
    name: 'goodsSearch',
    component: goodsSearch,
    meta: {
      title: '商品搜索',
      hiddenNavigation: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '404',
    component: Layout,
    children: [
      {
        path: '404',
        name: '404',
        component: NotFound,
        meta: {
          title: '404'
        }
      }
    ]
  },
  {
    path: '/no_permission',
    name: 'no_permission',
    component: NoJuisdiction,
    meta: {
      title: '首页',
      hiddenNavigation: true
    }
  },
  {
    path: '/loading',
    name: 'loading',
    component: Loading,
    meta: {
      title: '跳转中...',
      hiddenNavigation: true
    }
  }
]

// 3. Create the router instance and pass the `routes` option
export const router = createRouter({
  history: createWebHistory(),
  routes
})

// 4. Global Before Guards
router.beforeEach(async (to, from) => {
  // 动态标题
  if (to.meta.title) {
    document.title = to.meta.title
  }

  //---------------------------本地调试---------
  //   console.log('环境：', process.env.NODE_ENV)
  return await canUserAccess(to)
})

async function canUserAccess(to) {
  console.log('访问路径', to)
  // 预览文件界面，不作验证
  if (to.path == '/preview-page') {
    return true
  }
  //企业微信登录授权  添加token有效性检测
  if (!store.getters.token) {
    // if (!checkToken()) {
    if (to.query.code) {
      console.log('登录code:', to.query.code)
      let code = ''
      if (typeof to.query.code == 'string') {
        code = to.query.code
      } else {
        code = to.query.code[to.query.code.length - 1]
      }
      const { data } = await getWechatToken(code)
      console.log(data)
      store.commit('user/SET_TOKEN', data.token)
      store.commit('user/SET_INFO', data.user_info)
      localStorage.setItem('chain_corp_id', data.chain_corp_id)
      return true
    } else {
      authLogin()
    }
  }
}

/**
 * 检测token是否有效
 * @return {boolean}
 */
function checkToken() {
  if (store.getters.token) {
    let jwt_payload = store.getters.token.split('.')[1]
    console.log('token_payload:', jwt_payload)
    if (jwt_payload) {
      let res = window.atob(jwt_payload)
      if (res.exp < Date.parse(new Date()) / 1000) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

// 5. Global After Hooks
router.afterEach((to, from) => {
  sendToAnalytics(to.fullPath)
})

function sendToAnalytics(path) {
  console.log(path)
}
