import { createApp } from 'vue'
import App from './App.vue'
import 'vant/lib/index.css'

import { store } from './store'
import { router } from './router'
import '@/assets/style/reset.css'
import '@/assets/style/public.less'
import { Toast } from 'vant'
import VueMatomo from 'vue-matomo'
import Vconsole from 'vconsole'
import {
  Button,
  Cell,
  CellGroup,
  CountDown,
  Icon,
  List,
  PullRefresh,
  Popover,
  Sidebar,
  SidebarItem,
  Field,
  Tabs,
  Tab,
  Collapse,
  CollapseItem,
  Empty,
  DropdownMenu,
  DropdownItem,
  ActionSheet,
  Search,
  Sticky
} from 'vant'

const app = createApp(App)
app
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(CountDown)
  .use(Icon)
  .use(List)
  .use(PullRefresh)
  .use(Popover)
  .use(Sidebar)
  .use(SidebarItem)
  .use(Tabs)
  .use(Tab)
  .use(Field)
  .use(Collapse)
  .use(CollapseItem)
  .use(Empty)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(ActionSheet)
  .use(Search)
  .use(Sticky)
app.use('VueMatomo', {
  host: 'https://tongji.freshgood.cn/',
  siteId: 1
})
app.use(store).use(router).use(Toast).mount('#app')

console.log('window.location', window.location)
if (
  window.location.origin === 'https://we-customer-guide.test.freshgood.cn' ||
  window.location.origin === 'https://we-customer-guide.dev.freshgood.cn'
) {
  new Vconsole()
}
