<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png" />
  <HelloWorld msg="Hello Vue 3 + Vite" /> -->
  <router-view v-slot="{ Component }" v-if="isRouterAlive">
    <!-- <keep-alive> -->
    <component :is="Component" />
    <!-- </keep-alive> -->
  </router-view>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import { ref, nextTick } from 'vue'
export default {
  provide() {
    return {
      $refresh: this.refresh
    }
  },
  setup() {
    const isRouterAlive = ref(true)
    const refresh = async () => {
      isRouterAlive.value = false
      await nextTick()
      isRouterAlive.value = true
    }
    return {
      isRouterAlive,
      refresh
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #f7f8fa;
}
</style>
