// vuex@next https://next.vuex.vuejs.org/
// vuex-persistedstate https://github.com/robinvdvleuten/vuex-persistedstate

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import getters from './getters'
import user from './modules/user'

export const store = createStore({
  modules: {
    user
  },
  getters,
  plugins: [createPersistedState()]
})
